import React, { Fragment } from 'react';
import Head from 'components/LV1/Seo';
import NotFound from 'components/LV3/NotFound';

const NotFoundPage: React.FC = () => (
  <Fragment>
    <Head
      title="ページが存在しません。"
      isNoIndex
    />
    <NotFound />
  </Fragment>
);
export default NotFoundPage;