import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Path } from 'components/variables/path';

const Wrap = styled.div`
  color: #000;
  background-image: linear-gradient(90deg, #faf7f4 82%, #efe9e6 18%);
`;

const Inner = styled.div`
  padding: 280px 0 180px;
  text-align: center;
  h1 {
    font-size: 8rem;
    letter-spacing: 0.1em;
    font-family: 'Marcellus', serif;
  }
  h2 {
    font-size: 3rem;
    letter-spacing: 0.1em;
    font-family: 'Marcellus', serif;
  }
  h3 {
    margin: 20px auto 60px;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
  }
`;

const LinkStyled = styled(Link)`
  position: relative;
  padding-left: 50px;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: 'Marcellus', serif;
  color: #000;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -16px;
    width: 50px;
    height: 1px;
    background: #000;
    transition: all .3s;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    left: -17px;
    width: 16px;
    height: 1px;
    background: #000;
    transform: rotate(-35deg);
    transition: all .3s;
  }
`;

const NotFound: React.FC = () => {
  return (
    <Wrap>
      <Inner>
        <h1>404</h1>
        <h2>PAGE NOT FOUND</h2>
        <h3>お探しのページは見つかりませんでした</h3>
        <LinkStyled to={Path.top}>BACK TO HOME</LinkStyled>
      </Inner>
    </Wrap>
  );
};
export default NotFound;
