import React, { Fragment } from 'react';
import { Title, Link, Meta } from "react-head";

import ImgOg from "src/images/common/og.jpg";

type Props = {
  title?: string;
  description?: string;
  isNoIndex?: boolean;
};

const Aos: React.FC<Props> = (props: Props) => {
  const { title, description, isNoIndex } = props;
  // const baseDomain = 'coc-labo.co.jp';
  const baseDomain = "coclabo-corporate.vercel.app";
  const baseUrl = "https://" + baseDomain;
  const baseSiteName = "コクラボ株式会社";
  const baseDescription = "コクラボ株式会社はスピード・品質を重視したワンストップ開発を強みとしているIT企業です。スタートアップ・ベンチャーからエンタープライズ企業まで、さまざまな種類のWebサイトやWebサービスを企画・開発しています。クリエイティブ・マーケティング・広告・データ分析などの領域も対応可能です。";

  const setTitle = title ? title + " | " + baseSiteName : baseSiteName;
  const setDescription = description || baseDescription;
  const setUrlOg = baseUrl + ImgOg;

  return (
    <Fragment>
      <Title>{setTitle}</Title>
      <Meta name="description" content={setDescription} />
      <Meta property="og:site_name" content={baseSiteName} />
      <Meta property="og:type" content="website" />
      <Meta property="og:title" content={setTitle} />
      <Meta property="og:description" content={setDescription} />
      <Meta property="og:url" content={baseUrl} />
      <Meta property="og:image" content={setUrlOg} />
      <Meta property="og:image:secure_url" content={setUrlOg} />
      <Meta property="og:image:alt" content={baseSiteName} />
      <Meta property="twitter:card" content="summary_large_image" />
      <Meta property="twitter:domain" content={baseDomain} />
      <Meta property="twitter:title" content={setTitle} />
      <Meta property="twitter:description" content={setDescription} />
      <Meta property="twitter:image" content={setUrlOg} />
      {isNoIndex && <meta name="robots" content="noindex,nofollow" />}
      <Link rel="preconnect" href="https://fonts.googleapis.com" />
      <Link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <Link href="https://fonts.googleapis.com/css2?family=Marcellus&display=swap" rel="stylesheet" />
      <Link rel="stylesheet" href="https://unpkg.com/swiper@7.2.0/swiper-bundle.min.css" />
    </Fragment>
  )
}

export default Aos;